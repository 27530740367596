import { Action0 } from '@/utils/Types'
import { MutableRefObject, useEffect, useRef } from 'react'

export function useMounted() {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}

export const useRenderCounter = label => {
  const counter = useRef(0)
  counter.current++
  console.log(`RENDER COUNTER: ${label} rendered ${counter.current} times`)
}

export function useScrollable(scrollable: boolean) {
  useEffect(() => {
    if (scrollable) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [scrollable])
}

export function useClickOutside(ref: MutableRefObject<any>, fn: Action0) {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      fn()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
}
