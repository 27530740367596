import type { CountryCode } from 'libphonenumber-js';

export const API_URL = {
  login: '/user/login',
  register: '/user/register',
  forgetPass: '/user/forget-pass',
  resetPass: '/user/reset-valid',
  changePass: '/api/user/reset-pass',
  verifyEmail: '/user/verify-email',
  sendEmailVerify: '/api/user/email-verify',
  logout: '/user/logout',
  getUserInfo: '/api/user/info',
  postUserInfo: '/api/user/info',
  getUserAddresses: '/api/user/addresses',
  postUserAddress: '/api/user/address',
  removeUserAddress: '/api/user/address-remove',
  getUserOrder: '/api/user/order-details',
  getUserOrders: '/api/user/orders',
  userCreditBalance: '/api/user/balance',
  userCreditTrans: '/api/user/balance-transactions',
  userCashBalance: '/api/user/cash/get',
  userCashTrans: '/api/user/cash/transactions', // +action
  cashWithdraw: '/api/user/cash/withdraw',
  cashWithdrawCancel: '/api/user/cash/withdraw-cancel',
  socialLogin: '/services/login/socialite/',
  category: '/home/category-competitions',
  competitionDetail: '/competitions/competition-details',
  instantWinList: '/competitions/competition-instant-wins',
  categoryList: '/competitions/catalogs',
  competitionList: '/competitions/list',
  recommend: '/competitions/recommend',
  banners: '/home/banners',
  subscribe: '/home/subscribe',
  receiveNotif: '/home/receive-notifications',
  stats: '/home/stats',
  winners: '/winners/list',
  draws: '/draw-list',
  entries: '/entry-list',
  cartCount: '/cart/count',
  addToCart: '/cart/add-to-cart',
  cartDetail: '/cart/details',
  placeOrder: '/api/cart/place-order',
  cartValidate: '/api/cart/validate',
  checkout: '/api/cart/checkout',
  applePayCallback: '/api/service/apple-pay/callback',
  clearCart: '/cart/clear',
  updateCart: '/cart/update',
  orderDetail: '/api/order/details',
  addCoupon: '/cart/add-coupon',
  useCreditBalance: '/api/cart/use-balance', // credit
  useCashBalance: '/api/cart/use-wallet', // cash
  donations: '/donations',
  topUp: '/api/user/top-up/charge',
  topUpOptions: '/api/user/top-up/options',
  topUpHistory: '/api/user/top-up/payments',
  readNotif: '/api/user/read-notifications',
  selectReward: '/api/user/reward-select',
  captureContext: '/api/service/barclaycard/token',
  barclayEnroll: '/api/service/barclaycard/enrollment',
  allUserTix: '/api/user/tickets/get',
}

export const COUNTRY_LIST: Array<{ name: string; code: CountryCode }> = [
  { name: 'United Kingdom', code: 'GB' },
  { name: 'Republic of Ireland', code: 'IE' },
]

export const IE_COUNTY_LIST = [
  { code: 'CW', name: 'Carlow' },
  { code: 'CN', name: 'Cavan' },
  { code: 'CE', name: 'Clare' },
  { code: 'CO', name: 'Cork' },
  { code: 'DL', name: 'Donegal' },
  { code: 'D', name: 'Dublin' },
  { code: 'G', name: 'Galway' },
  { code: 'KY', name: 'Kerry' },
  { code: 'KE', name: 'Kildare' },
  { code: 'KK', name: 'Kilkenny' },
  { code: 'LS', name: 'Laois' },
  { code: 'LM', name: 'Leitrim' },
  { code: 'LK', name: 'Limerick' },
  { code: 'LD', name: 'Longford' },
  { code: 'LH', name: 'Louth' },
  { code: 'MO', name: 'Mayo' },
  { code: 'MH', name: 'Meath' },
  { code: 'MN', name: 'Monaghan' },
  { code: 'OY', name: 'Offaly' },
  { code: 'RN', name: 'Roscommon' },
  { code: 'SO', name: 'Sligo' },
  { code: 'TA', name: 'Tipperary' },
  { code: 'WD', name: 'Waterford' },
  { code: 'WH', name: 'Westmeath' },
  { code: 'WX', name: 'Wexford' },
  { code: 'WW', name: 'Wicklow' },
]

export const LATEST_WINNERS_PER_PAGE = 14 // must be different from winners page perPage

export const SOCIAL_MEDIA_FACEBOOK = 'https://www.facebook.com/profile.php?id=100077330920045/'
