import KlaviyoScript from '@/components/KlaviyoScript'
import { useAuth } from '@/hooks/Auth'
import {
  defaultScriptState,
  defaultStats,
  defaultSystemConfig,
  desktopLayout,
  LanguageContext,
  Layout,
  LayoutContext,
  mobileLayout,
  MyCartContext,
  ScriptStateContext,
  StatsContext,
  SystemConfigContext,
  UserContext
} from '@/hooks/Contexts'
import { useGA } from '@/hooks/GoogleAnalytics'
import { useGTM } from '@/hooks/GoogleTagManager'
import { useMounted } from '@/hooks/Index'
import { API_URL } from '@/utils/Consts'
import ReactPixel, { AdvancedMatching } from '@juanlatorre/react-facebook-pixel'
import { CartCount } from '@models/Response'
import { appUtils } from 'lib/AppUtils'
import { setupInterceptors } from 'lib/axios'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { hotjar } from 'react-hotjar'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useSWR from 'swr'
import english from '../public/lang/en.json'
import spanish from '../public/lang/es-MX.json'
import '../styles/globals.css'
import '../utils/Extensions'
const Maintenance = dynamic(() => import('./maintenance'))
const Footer = dynamic(() => import('../components/Navigation/Footer'))
const Navbar = dynamic(() => import('../components/Navigation/Navbar'))
const Notification = dynamic(() => import('../components/Notification'))

function MyApp(props: any) {
  const [system, setSystem] = useState(defaultSystemConfig)
  const [layout, setLayout] = useState<Layout>({} as Layout)
  const [strings, setStrings] = useState(english)
  const [stats, setStats] = useState(defaultStats)
  const [scriptState, setScriptState] = useState(defaultScriptState)

  const router = useRouter()
  const { user, logout } = useAuth()
  useEffect(() => setupInterceptors(logout), [])

  const isMounted = useMounted()

  const { initGTM } = useGTM()
  const { initGA } = useGA()

  useEffect(() => {
    initGTM()
    initGA()

    if (process.env.NEXT_PUBLIC_HOT_JAR_ID) {
      const hotId = process.env.NEXT_PUBLIC_HOT_JAR_ID
      hotjar.initialize(Number.parseInt(hotId), 6)
    }

    // Facebook pixel tracking
    if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_EMAIL) {
      const advancedMatching = {
        em: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_EMAIL,
      } as AdvancedMatching
      const options = { autoConfig: true, debug: false }
      if (typeof window !== 'undefined') {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID, advancedMatching, options)
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID2, advancedMatching, options)
        ReactPixel.fbq('trackSingle', process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID, 'PageView')
        ReactPixel.fbq('trackSingle', process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID2, 'PageView')
      }
    }

    /**
     * System related
     */
    if (typeof window !== 'undefined')
      setSystem(prev => ({
        ...prev,
        isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent.toLowerCase()),
        screen: { width: window.innerWidth, height: window.innerHeight },
      }))

    appUtils
      .fetcher(API_URL.stats, false)
      .then(res => {
        if (res) setStats(res)
      })
      .catch(() => {})

    if (scriptState.flexMicroformScript === 'notloaded') {
      appUtils.evaluateRemoteScript(
        process.env.NEXT_PUBLIC_FLEX_MICROFORM,
        () => {
          if (!isMounted) {
            return
          }
          setScriptState(s => ({ ...s, flexMicroformScript: 'loaded' }))
        },
        () => {
          if (!isMounted) {
            return
          }
          setScriptState(s => ({ ...s, flexMicroformScript: 'loading' }))
        }
      )
    }
  }, [])

  // Register service worker
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker
          .register('/serviceWorker.js')
          .then()
          .catch(() => {})
        // function (registration) {
        //   console.log('registration successful with scope: ', registration.scope)
        // },
        // function (err) {
        //   console.log('registration failed: ', err)
        // }
      })
    }
  }, [])

  /**
   * Layout Context
   */
  const onResize = useCallback(() => {
    const windowWidth = window?.innerWidth
    if (windowWidth >= 768) {
      setLayout({
        ...desktopLayout,
        breakpoint: windowWidth >= 1200 ? '2xl' : windowWidth >= 1140 ? 'xl' : windowWidth >= 992 ? 'lg' : 'md',
      })
    } else {
      setLayout({ ...mobileLayout, breakpoint: windowWidth >= 576 ? 'sm' : windowWidth >= 375 ? 'xs' : '2xs' })
    }
  }, [])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])

  /**
   * Multi Language support
   */
  useEffect(() => {
    switch (router.locale) {
      case 'es-MX':
        setStrings(spanish)
        return
      default:
        setStrings(english)
        return
    }
  }, [router.locale])

  /**
   * Spy on next.router route change
   */
  useEffect(() => {
    const handleRouteChange = url => {
      const isNotAuthRoute = ['/maintenance', '/404', '/login', '/register', '/forget-password', '/reset-password', '/user', '/cart/'].every(
        route => !router.pathname.startsWith(route)
      )
      // Current page is not auth page, also, Target page is login or register
      if (isNotAuthRoute && (url === '/login' || url === '/register')) {
        sessionStorage.setItem('pathBeforeAuth', router.asPath)
      } else {
        // Current page is auth routes already or Target page is not login nor register
        // Do nothing
        return
      }
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.pathname])

  const { data: cartCount } = useSWR<CartCount>(API_URL.cartCount, appUtils.fetcher, {
    shouldRetryOnError: false,
    focusThrottleInterval: 60000,
    onError: err => {
      if (err && err.response) {
        if (err.response.status === 503) {
          if (!system.isMaintain) {
            setSystem(prev => ({ ...prev, isMaintain: true }))
          }
        } else if (system.isMaintain) {
          setSystem(prev => ({ ...prev, isMaintain: false }))
        }
      }
      // else if ('Network Error' == err.message) {
      //   if (!system.isMaintain) {
      //     setSystem(prev => ({ ...prev, isMaintain: true }))
      //   }
      // }
    },
    errorRetryCount: 5,
  })

  return (
    <SystemConfigContext.Provider value={system}>
      <LayoutContext.Provider value={layout}>
        <LanguageContext.Provider value={strings}>
          {system.isMaintain ? (
            <Maintenance />
          ) : (
            <>
              <StatsContext.Provider value={stats}>
                <UserContext.Provider value={user}>
                  <MyCartContext.Provider value={cartCount}>
                    <ScriptStateContext.Provider value={scriptState}>
                      {router.pathname.includes('/checkout') ? null : <Navbar />}
                      {router.pathname == '/cart/[orderId]' ? null : <Notification />}
                      <props.Component {...props?.pageProps} />
                      {router.pathname.includes('/checkout') ? null : <Footer />}
                      <ToastContainer className='min-w-fit text-sm md:text-base' position='top-center' limit={3} />
                    </ScriptStateContext.Provider>
                  </MyCartContext.Provider>
                </UserContext.Provider>
              </StatsContext.Provider>
              <KlaviyoScript user={user} />
            </>
          )}
        </LanguageContext.Provider>
      </LayoutContext.Provider>
    </SystemConfigContext.Provider>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object,
}

export default MyApp
