import { API_URL } from '@/utils/Consts'
import { SupportedOAuthProviders } from '@/utils/Types'
import { appUtils } from 'lib/AppUtils'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'
import type { UrlObject } from 'url'
import axios from '../lib/axios'
import type { CartCount } from '../models/Response'
import { useLanguage } from './Contexts'
import { useGTM } from './GoogleTagManager'
import { useMounted } from './Index'

export const useAuth = ({ middleware = '', redirectIfAuthenticated = '' } = {}) => {
  const router = useRouter()
  const isMounted = useMounted()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)

  const { errors: errStr } = useLanguage()

  const {
    data: user,
    isValidating,
    isLoading,
    error,
    mutate,
  } = useSWRImmutable(
    API_URL.getUserInfo,
    url =>
      axios
        .get(url)
        .then(res => res.data.data)
        .catch(error => {
          throw error
        }),
    { shouldRetryOnError: false }
  )

  const { mutate: swrMutate } = useSWRConfig()
  const { logEventGTM } = useGTM()

  const csrf = () => axios.get('/sanctum/csrf-cookie')
  const doAuth = async (url: string, props: {}) => {
    setLoading(true)
    setErrors('')
    await csrf()
    await axios
      .post(url, props)
      .then(res => {
        if (res && res.data && res.data.code != '0') {
          if (isMounted) setErrors(appUtils.getErrorMsgByCode(res.data.code, errStr))
        } else {
          mutate()
          swrMutate(API_URL.cartCount)
          if (url == API_URL.register) {
            const params = {
              dataLayer: {
                event: 'registration_success',
                event_category: 'register',
                event_action: 'action_complete',
                event_label: 'Registration Third Step',
              },
            }
            logEventGTM(params)
          }
        }
        if (isMounted) setLoading(false)
      })
      .catch(e => {
        if (isMounted) {
          setLoading(false)
          setErrors(appUtils.handleApiError(e, errStr))
        }
      })
  }

  const getSocialLoginLink = async (provider: SupportedOAuthProviders) => {
    setErrors('')
    setLoading(true)
    await axios
      .get(API_URL.socialLogin + provider)
      .then(response => {
        if (response.status === 200) {
          return router.push(response.data.data.url)
        }
      })
      .catch(e => {
        if (isMounted) {
          setLoading(false)
          setErrors(appUtils.handleApiError(e, errStr))
        }
      })
  }

  /**
   * @param localMutation Mutate the user locally without refetching user info from remote
   * @param redirect Redirect path after local mutation
   * @returns
   */
  const logout = async (localMutation?: Boolean, redirect?: UrlObject | string) => {
    const mutateOpts = {
      optimisticData: null, // data to immediately update the client cache
      revalidate: false, // should the cache revalidate
      populateCache: false, // should the result of the remote mutation be written to the cache
      rollbackOnError: false, // should the cache rollback if the remote mutation errors
    }
    const newCartCnt: CartCount = { id: 0, items: 0, grant_total: 0 }

    const localMutate = () => {
      mutate(null, mutateOpts)
      swrMutate(API_URL.cartCount, newCartCnt, { ...mutateOpts, optimisticData: newCartCnt })
    }

    if (localMutation) {
      localMutate()
      if (redirect) {
        router.push(redirect)
      }
    } else if (!error) {
      const res = await axios.post(API_URL.logout)
      if (res?.status === 204) {
        localMutate()
        router.push('/login') // don't refresh
      } else {
        window.location.pathname = '/login' // refresh page
      }
    } else {
      window.location.pathname = '/login' // refresh page
    }
  }

  useEffect(() => {
    if (middleware === 'guest' && redirectIfAuthenticated && user) {
      router.push(redirectIfAuthenticated)
    }
    if (middleware === 'auth' && error) logout()
  }, [user, error])

  // Get Social-Login or Reset-Password or Verify-Email Error message via query
  useEffect(() => {
    if (!router.isReady) return
    if (['/login', '/reset-password', '/user/email-verify'].indexOf(router.pathname) > -1 && router.query.error) {
      setErrors(appUtils.getErrorMsgByCode(router.query.error.toString(), errStr))
    }
  }, [router.query])
  return {
    user,
    userError: error,
    doAuth,
    isLoading: loading,
    errors,
    getSocialLoginLink,
    logout,
    isMutating: isValidating || isLoading,
  }
}
