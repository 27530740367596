import Axios from 'axios'
import { API_URL } from '@/utils/Consts'
import type { UrlObject } from 'url'

const axios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'X-Requested-With': 'XMLHttpRequest',
    'accept': 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

/**
 * Globally intercept all api calls made by axios.
 *
 * @param logout from useAuth() hook
 */
// eslint-disable-next-line no-unused-vars
export const setupInterceptors = (logout: (localMutation: Boolean, redirect: UrlObject | string) => Promise<void>) => {
  axios.interceptors.response.use(
    response => {
      return response
    },

    error => {
      /**
       * Any status codes that falls outside the range of 2xx cause this function to trigger
       *    401 -- unauthorized
       *    419 -- session expired
       */

      // 1011 -- user suspended
      if (error.response?.data?.code == 1011 && error.config.url !== API_URL.login && window.location.pathname !== '/login') {
        logout(true, { pathname: '/', query: { error: 1011 } })
      }

      return Promise.reject(error)
    }
  )
}

export default axios
